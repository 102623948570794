import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/MasterCategories.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/get-a-quote',
    name: 'Get A Quote',
    component: () => import('../views/GetAQuote.vue')
  },
  // Members
  {
    path: '/members',
    name: 'Member Search',
    component: () => import('../views/MemberSearch.vue')
  },
  {
    path: '/members/:memberSlug',
    name: 'Member Details',
    component: () => import('../views/MemberDetails.vue')
  },
  // Toolbox Talk
  {
    path: '/toolbox-talk',
    name: 'Toolbox Talk',
    component: () => import('../views/ToolboxTalk.vue')
  },
  {
    path: '/toolbox-talk/:slug',
    name: 'Toolbox Talk Details',
    component: () => import('../views/ToolboxTalkDetails.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../views/Demo.vue')
  },
  // Events
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/events/:slug',
    name: 'Event Details',
    component: () => import('../views/EventDetails.vue')
  },
  // About
  {
    path: '/about/about-us',
    name: 'About Us',
    component: () => import('../views/about/AboutUs.vue')
  },
  {
    path: '/about/pricing',
    name: 'Pricing Schedule',
    component: () => import('../views/about/PricingSchedule.vue')
  },
  {
    path: '/about/benefits',
    name: 'Member Benefits',
    component: () => import('../views/about/MemberBenefits.vue')
  },
  {
    path: '/about/faq',
    name: 'FAQ',
    component: () => import('../views/about/Faqs.vue')
  },
  {
    path: '/about/websites',
    name: 'FAQ',
    component: () => import('../views/about/OurWebsites.vue')
  },
  {
    path: '/about/promo',
    name: 'Promo Packages',
    component: () => import('../views/about/PromoPackages.vue')
  },
  {
    path: '/about/non-profits',
    name: 'NonProfits',
    component: () => import('../views/about/NonProfits.vue')
  },
  {
    path: '/about/opportunities',
    name: 'Opportunities',
    component: () => import('../views/about/Opportunities.vue')
  },
  {
    path: '/about/our-story',
    name: 'Our Story',
    component: () => import('../views/about/OurStory.vue')
  },
  {
    path: '/about/our-story/contd',
    name: 'Our Story Contd',
    component: () => import('../views/about/our-story/OurStoryContd.vue')
  },
  {
    path: '/about/our-story/original-ideals',
    name: 'Original Ideals',
    component: () => import('../views/about/our-story/OriginalIdeals.vue')
  },
  {
    path: '/about/our-story/the-founder',
    name: 'The Founder',
    component: () => import('../views/about/our-story/TheFounder.vue')
  },
  {
    path: '/about/our-story/evolution',
    name: 'Our Evolution',
    component: () => import('../views/about/our-story/OurEvolution.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
