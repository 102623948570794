
import Card from '@/components/layout/Card.vue'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'
// Import Swiper styles
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Autoplay])

@Component({
  components: {
    Card,
    Swiper,
    SwiperSlide
  }
})

export default class RecentBranches extends Vue {
  private recentBranches: BusinessBranchModel[] | null = null;
  private gettingRecentBranches = false;

  private settings = {

  }

  private breakpoints = {
    // when window width is >= 320px
    1080: {
      slidesPerView: 6,
      spaceBetween: 30
    },
    // when window width is >= 480px
    900: {
      slidesPerView: 4,
      spaceBetween: 20
    },
    // when window width is >= 640px
    600: {
      slidesPerView: 2,
      spaceBetween: 10
    }
  }

  mounted (): void {
    this.getRecentUsers()
  }

  getRecentUsers (): void {
    this.gettingRecentBranches = true
    Services.API.Businesses.GetRecentBranchesForPublic(20)
      .then(returnBranches => {
        this.recentBranches = returnBranches
      }).finally(() => {
        this.gettingRecentBranches = false
      })
  }

  recentMemberClick (slug: string): void {
    this.$router.push('/members/' + slug)
  }
}

