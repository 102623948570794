
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ContentContainer extends Vue {
  @Prop({ default: 'transparent' })
  private readonly background!: string;

  @Prop()
  private readonly rounded!: boolean;
}
