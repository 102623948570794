
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class Observer extends Vue {
  // Where the basis is for the element 100px before on screen
  @Prop({ default: '0px' })
  private readonly rootMargin!: string;

  // How much must be on the screen until its visible 0 -> 1
  @Prop({ default: 0.5 })
  private readonly threshold!: number;

  // Unobserve after one intersection
  @Prop({ default: false })
  private readonly once!: boolean;

  protected observer: IntersectionObserver | null = null

  mounted (): void {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.$emit('intersecting', entry)
          if (this.once && this.observer != null) {
            this.observer.unobserve(entry.target)
          }
        }
      })
    },
    {
      rootMargin: this.rootMargin,
      threshold: this.threshold
    })

    this.observer.observe(this.$el)
  }

  destroyed (): void {
    if (!this.observer) return
    this.observer.disconnect()
  }
}

