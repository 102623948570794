
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ZoneSelector from '@/components/collections/zones/ZoneSelector.vue'
import CategorySelector from '@/components/collections/category/CategorySelector.vue'
import { AutocompleteDropdownResult } from '@/components/forms/AutocompleteDropdownField.vue'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import BusinessBranchOrCategorySearchResult
  from '../../../../../tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'

@Component({
  components: {
    ZoneSelector,
    CategorySelector
  }
})
export default class SearchBar extends Vue {
  private tradingCategories: AutocompleteDropdownResult[] | null = null;

  private catSearchTerm = ''
  private zoneSearchTerm = ''
  private zones: ZoneModel[] = []
  private searchResults: BusinessBranchOrCategorySearchResult[] = []

  @Prop({ default: '' })
  private readonly categorySearchResponse!: string | null

  @Prop({ default: '' })
  private readonly zoneSearchResponse!: string | null

  @Prop({ default: null })
  private readonly zoneResponse!: ZoneModel | null

  @Prop({ default: null })
  private readonly categoryResponse!: BusinessBranchOrCategorySearchResult | null

  // Apply last search result that isn't already filled in
  // Otherwise make sure it has been reset
  @Watch('categorySearchResponse')
  @Watch('zoneSearchResponse')
  @Watch('zoneResponse')
  @Watch('categoryResponse')
  private updateStuff (): void {
    console.log('update stuff')
    console.log(this.categoryResponse)
    if (this.catSearchTerm === '' && this.categorySearchResponse != null) {
      this.catSearchTerm = this.categorySearchResponse
    }

    if (this.zoneSearchTerm === '' && this.zoneSearchResponse != null) {
      this.zoneSearchTerm = this.zoneSearchResponse
    }

    if (!this.zones.length && this.zoneResponse != null) {
      this.zones = [this.zoneResponse]
    }

    if (!this.searchResults.find(x => x.Id === this.categoryResponse?.Id) && this.categoryResponse != null) {
      this.searchResults = [this.categoryResponse]
    }
  }

  updateFields (): void {
    const ss = this.$route.query.s?.toString()
    const zs = this.$route.query.zs?.toString()
    const categoryIds = this.$route.query.categories?.toString().split(',')
    const zoneIds = this.$route.query.zones?.toString().split(',')
    if (ss.length) {
      this.catSearchTerm = ss
    } else if (categoryIds.length) {
      Services.API.Categories.Query(categoryIds)
        .then((result) => {
          this.searchResults = result
        })
    }

    if (zs.length) {
      this.zoneSearchTerm = zs
    } else if (zoneIds.length) {
      Services.API.Zones.QueryZones(zoneIds)
        .then((result) => {
          this.zones = result
        })
    }
  }

  search (): void {
    const categoryIds = this.searchResults.map((item) => { return item.Id })
    const zoneIds = this.zones.map((item) => { return item.ZoneId })
    this.$router.push({
      path: '/members',
      query: {
        categories: categoryIds ?? undefined,
        zones: zoneIds ?? undefined,
        s: this.catSearchTerm.length ? this.catSearchTerm : undefined,
        zs: this.zoneSearchTerm ? this.zoneSearchTerm : undefined
      }
    })
  }

  onSearchTermUpdated (searchTerm: string): void {
    this.catSearchTerm = searchTerm
  }

  onZoneSearchTermUpdated (searchTerm: string): void {
    this.zoneSearchTerm = searchTerm
  }

  getEyebrow (cat: CategoryModel): string {
    if (cat.Parent) {
      this.getEyebrow(cat.Parent)
    }
    return cat.Name + ' > '
  }
}
