
import { Config } from 'tradingmate-modules/src/environment'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'

@Component({
  components: {
    NavigationLink
  }
})
export default class Footer extends Vue {
  private popularCategories: CategoryModel[] | null = null;
  private registerLink = Config.WebsiteUrls.Console + '/account/register'

  mounted (): void {
    this.getCategories()
  }

  getCategories (): void {
    Services.API.Categories.GetPopular(5).then((returnCategories) => {
      this.popularCategories = returnCategories
    })
  }

  private Config = Config
}
