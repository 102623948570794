
import { Vue, Component, Prop } from 'vue-property-decorator'
import Config from '@/config'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import PackageCheckoutQueries from '../../../../../tradingmate-modules/src/models/common/PackageCheckoutQueries'
import Heading from '@/components/typography/Heading.vue'
import VerticalHeading from '@/components/typography/VerticalHeading.vue'
SwiperCore.use([Navigation, Pagination])

@Component({
  components: {
    VerticalHeading,
    Heading,
    Swiper,
    SwiperSlide
  }
})

export default class Packages extends Vue {
  private onCtaClick (packageQuery: string): void {
    const url = Config.WebsiteUrls.Console + '/account/register' + '?p=' + packageQuery
    window.open(url, '_self')
  }

  private onB2bClicked (): void {
    this.onCtaClick(PackageCheckoutQueries.B2b)
  }

  private onStatewideClicked (): void {
    this.onCtaClick(PackageCheckoutQueries.StateWide)
  }

  private onWebsiteClicked (): void {
    this.onCtaClick(PackageCheckoutQueries.Website)
  }

  private onPromoClicked (): void {
    this.onCtaClick(PackageCheckoutQueries.Promo)
  }

  private onNationalClicked (): void {
    this.onCtaClick(PackageCheckoutQueries.AllAussieExtra)
  }

  @Prop({ default: true })
  private showStatewide!: boolean

  get breakpoints (): unknown {
    return {
      1800: {
        slidesPerView: this.showStatewide ? 6 : 5,
        spaceBetween: 10,
        allowTouchMove: false,
        navigation: false
      },
      1500: {
        slidesPerView: this.showStatewide ? 4 : 3,
        spaceBetween: 10,
        allowTouchMove: true,
        centeredSlides: true,
        navigation: true
      },
      1200: {
        slidesPerView: this.showStatewide ? 3 : 2,
        spaceBetween: 20,
        allowTouchMove: true,
        centeredSlides: true,
        navigation: true
      },
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
        allowTouchMove: true,
        navigation: true,
        centeredSlides: true
      }
    }
  }
}
