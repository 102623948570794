
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavigationLink extends Vue {
  @Prop({ default: null })
  private readonly to!: string | null

  @Prop({ default: false })
  private readonly external!: boolean;

  @Prop()
  private readonly icon!: string;
}
