
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class FlexGroup extends Vue {
  @Prop({ default: 'start' })
  private readonly align!: 'start' | 'end' | 'center';

  @Prop({ default: 'medium' })
  private readonly spacing!: 'small' | 'medium' | 'large';
}

