
import { Config } from 'tradingmate-modules/src/environment'
import { Component, Vue } from 'vue-property-decorator'
import Navigation from './Navigation.vue'
import NavigationLink from './NavigationLink.vue'
import Utils from '../../../../tradingmate-modules/src/Utils'

@Component({
  components: {
    Navigation,
    NavigationLink
  }
})
export default class Header extends Vue {
  private isStuck = false
  private mobileNavActive = false;
  private consoleUrl = Config.WebsiteUrls.Console
  private isLoggedIn = false

  mounted (): void {
    window.addEventListener('scroll', this.checkScroll, { passive: true })

    this.checkScroll()

    this.getCookie()
  }

  getCookie (): void {
    const cookie = Utils.getCookie('SessionTokenId')
    if (cookie != null) {
      this.isLoggedIn = true
    }
  }

  checkScroll (): void {
    this.isStuck = window.scrollY > 0
  }

  CtaClicked (): void {
    window.open(this.consoleUrl + '/account/register', '_self')
  }

  signupClicked (): void {
    window.open(this.consoleUrl + '/account/login', '_self')
  }

  onBackToProfile (): void {
    window.open(this.consoleUrl, '_self')
  }
}
