
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class Link extends Vue {
  @Prop({ required: true })
  private readonly link!: string;

  @Prop({ default: true })
  private readonly isExternal!: boolean;

  @Prop({ default: 'none' })
  private readonly type!: 'none' | 'mail' | 'phone';

  get fullLink (): string {
    switch (this.type) {
      case 'none':
        if (this.link.includes('@')) {
          return `mailto:${this.link}`
        }
        return this.link
      case 'mail':
        return `mailto:${this.link}`
      case 'phone':
        return `tel:${this.link}`
      default:
        return ''
    }
  }
}

