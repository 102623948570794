
import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class PricingCard extends Mixins(Vue) {
  @Prop()
  private readonly character!: string;

  @Prop()
  private readonly title!: string

  @Prop()
  private readonly subtitle!: string

  @Prop()
  private readonly price!: number

  @Prop({ default: 'var(--brand-primary)' })
  private readonly colour!: string;
}
