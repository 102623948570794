
import { Vue, Component, Prop } from 'vue-property-decorator'
import FlexGroup from './FlexGroup.vue'
import LazyImage from './LazyImage.vue'

@Component({
  components: {
    LazyImage,
    FlexGroup
  }
})
export default class Card extends Vue {
  @Prop({ default: null })
  private readonly image!: string;

  @Prop({ default: '0.75' })
  private readonly imageAr!: string;

  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly isExternal!: boolean;

  @Prop({ default: null })
  private readonly altText!: string | null

  handleClicked (): void {
    if (this.isExternal) {
      window.open(this.href, '_blank')
    } else {
      this.$router.push(this.href)
    }
  }
}
