
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Row extends Vue {
  @Prop({ default: '1rem' })
  private readonly gutter!: number;

  @Prop({ default: 'flex-start' })
  private readonly verticalAlign!: string;

  @Prop({ default: 'space-between' })
  private readonly horizontalAlign!: string;

  @Prop({ default: false })
  private readonly reversed!: boolean;
}
