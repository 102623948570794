
import { Component } from 'vue-property-decorator'
import Utils from 'tradingmate-modules/src/Utils'
import InputText from '@/components/inputs/InputText.vue'
import { Services } from 'tradingmate-modules/src/services'
import EmailNewsletterSubscriptionCreateDto
  from 'tradingmate-modules/src/models/api/email-newsletter-subscriptions/EmailNewsletterSubscriptionCreateDto'
import { FormPage } from '@/mixins'

@Component({
  components: {
    InputText
  }
})

export default class NewsletterSignUp extends FormPage {
  private getCharacter = Utils.getCharacter()

  private createDto: EmailNewsletterSubscriptionCreateDto = { EmailAddress: '' }

  private submitted = false

  async onCreateSubscription (): Promise<void> {
    this.loading = true
    this.submitted = false
    console.log('on create')

    try {
      console.log('try')
      var result = await Services.API.EmailNewsletterSubscriptions.Create(this.createDto)
      console.log('result')
      console.log(result)
      this.submitted = true
    } catch (error) {
      this.httpError = error
      console.log(error)
    } finally {
      this.loading = false
    }
  }
}

