
import Vue from 'vue'

// actions
import Button from './actions/Button.vue'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'

// layout
import Page from '@/components/layout/Page.vue'
import ContentRow from '@/components/layout/ContentRow.vue'
import ContentContainer from '@/components/layout/ContentContainer.vue'
import Masthead from '@/components/layout/Masthead.vue'
import Row from '@/components/layout/Row.vue'
import Column from '@/components/layout/Column.vue'
import Cards from '@/components/layout/Cards.vue'
import Link from '@/components/layout/Link.vue'
import FlexRow from '@/components/layout/FlexRow.vue'

//  status
import Loader from './status/Loader.vue'
import InfoState from './status/InfoState.vue'

// typography
import Icon from '@/components/typography/Icon.vue'
import SectionTitle from '@/components/typography/SectionTitle.vue'
import SectionSubtitle from '@/components/typography/SectionSubtitle.vue'

// layout
Vue.component('Page', Page)
Vue.component('ContentRow', ContentRow)
Vue.component('ContentContainer', ContentContainer)
Vue.component('Masthead', Masthead)
Vue.component('Cards', Cards)
Vue.component('Link', Link)
Vue.component('FlexRow', FlexRow)

Vue.component('Row', Row)
Vue.component('Column', Column)

// action
Vue.component('Button', Button)

// form
Vue.component('Form', Form)
Vue.component('FormField', FormField)

// status
Vue.component('Loader', Loader)
Vue.component('InfoState', InfoState)

// typography
Vue.component('Icon', Icon)
Vue.component('SectionTitle', SectionTitle)
Vue.component('SectionSubtitle', SectionSubtitle)
