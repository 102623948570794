
import { Input } from '@/mixins'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import InputText from './InputText.vue'

export interface AutocompleteResult {
  Label: string;
  Value: string;
}

export interface AutocompleteProvider<TResult extends AutocompleteResult> {
  Search(term: string): Promise<TResult[]>;
  Suggest(term: string): Promise<string | null>;
}

@Component({
  components: {
    InputText
  },
  name: 'AutoCompleter'
})
export default class AutoCompleter extends Mixins(Input) {
  // Input
  private searchTerm = ''
  private readonly id = Utils.GetId()

  // @Prop({ required: true })
  // private searchTerm!: string;

  @Prop({ required: true })
  private readonly provider!: AutocompleteProvider<AutocompleteResult>;

  // Results
  private results: AutocompleteResult[] = [];

  @Prop({ default: 'No results' })
  private readonly noResultsText!: string;

  @Prop({ default: 'Keep typing for results' })
  private readonly noTermText!: string;

  @Prop({ default: 3 })
  private readonly minTermLength!: number;

  // Emits
  onResultSelected (selection: AutocompleteResult): void {
    this.$emit('resultSelected', selection)
    this.searchTerm = ''
    this.results = []
  }

  @Prop({ default: 500 })
  private readonly debounceTimeout!: number;

  private debounce: number | undefined = undefined
  private debounceCallbackId = ''
  private isSearching = false;

  @Watch('searchTerm')
  searchTermUpdated (): void {
    this.$emit('searchTermUpdated', this.searchTerm)
  }

  // Events
  onTermUpdated (): void {
    // this.searchTermUpdated()

    window.clearTimeout(this.debounce)
    if (this.searchTerm.length < this.minTermLength) return
    this.debounce = setTimeout(() => {
      const callbackId = Utils.GetId()
      this.debounceCallbackId = callbackId
      this.isSearching = true
      this.provider.Search(this.searchTerm).then((results) => {
        if (this.debounceCallbackId !== callbackId) {
          console.log('Discaring results as callback has changed')
          return
        }
        this.results = []
        this.results = results
      }).finally(() => { this.isSearching = false })
    }, this.debounceTimeout)
  }
}

