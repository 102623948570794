
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'

@Component({
  components: {
    NavigationLink
  }
})
export default class Navigation extends Vue {
  @Prop({ default: false })
  private readonly mobileNavActive!: boolean
}
