import AppConfig from './config'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Bootstrapper } from 'tradingmate-modules/src/startup'

import './components/global'

Vue.config.productionTip = false

Bootstrapper.Initialise(AppConfig).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
