import { render, staticRenderFns } from "./SectionSubtitle.vue?vue&type=template&id=6ee7e7ce&scoped=true"
var script = {}
import style0 from "./SectionSubtitle.vue?vue&type=style&index=0&id=6ee7e7ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee7e7ce",
  null
  
)

export default component.exports