
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ContentRow extends Vue {
  @Prop()
  private readonly pullUp!: boolean;

  @Prop()
  private readonly alt!: boolean;

  @Prop()
  private readonly image!: string;

  @Prop()
  private readonly colour!: string;

  @Prop({ default: true })
  private readonly darkenImage!: boolean;
}
